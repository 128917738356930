import React, { useMemo, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TestSuiteIcon, TestAPIIcon, BugTrackerIcon, IntegrationIcon } from "../../icons";
import { RiBug2Line } from "react-icons/ri";
import { CgLoadbarDoc } from "react-icons/cg";

export const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();

  // Use memoization to prevent re-creation of items array
  const items = useMemo(() => [
    { icon: <TestSuiteIcon />, label: 'Test Suite', url: ['/test-suite', '/test'] },
    { icon: <CgLoadbarDoc size={24} />, label: 'Test API', url: ['/test-api', '/test-api'] },
    { icon: <RiBug2Line size={24} />, label: 'Bug Tracker', url: ['/bug-tracker', '/bug'] },
    { icon: <IntegrationIcon />, label: 'Integrations', url: ['/integrations', '/integrations'] },
  ], []);

  const handleItemClick = useCallback((item) => {
    navigate(item.url[0]);
  }, [navigate]);

  return (
    <aside className="text-gray-400 flex flex-col items-center h-full border-r border-[#2C2E33] xl:p-4 md:p-2">
      {items.map((item) => (
        <SidebarItem
          key={item.label}
          item={item}
          isActive={location.pathname.includes(item.url[1])}
          onClick={handleItemClick}
        />
      ))}
    </aside>
  );
});

const SidebarItem = React.memo(({ item, isActive, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [onClick, item]);

  return (
    <div
      className={`flex flex-col items-center justify-center my-2 p-2 w-full rounded-lg cursor-pointer
        ${isActive ? 'bg-dashboard-dark-600 border border-dashboard-border-500 text-white' : 'hover:bg-dashboard-dark-600 text-gray-400'}`}
      onClick={handleClick}
    >
      <div className="text-2xl">{item.icon}</div>
      <span className="text-sm mt-2 text-center">{item.label}</span>
    </div>
  );
});


/* export const Sidebar = () => (
  <aside className="h-full bg-black border-r border-gray-700 flex flex-col items-center py-4 gap-6">
    <div className="flex flex-col items-center gap-1">
      <div className="p-2 bg-gray-800 rounded">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
          <rect x="7" y="7" width="10" height="10"></rect>
        </svg>
      </div>
      <span className="text-xs text-gray-400">Test Suite</span>
    </div>
    
    <div className="flex flex-col items-center gap-1">
      <div className="p-2">
        <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
        </svg>
      </div>
      <span className="text-xs text-gray-400">Test API</span>
    </div>
    
    <div className="flex flex-col items-center gap-1">
      <div className="p-2">
        <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <circle cx="12" cy="12" r="10" />
          <path d="M12 16v-4M12 8h.01" />
        </svg>
      </div>
      <span className="text-xs text-gray-400">Bug Tracker</span>
    </div>

    <div className="flex flex-col items-center gap-1">
      <div className="p-2">
        <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <circle cx="12" cy="12" r="10" />
          <path d="M12 16v-4M12 8h.01" />
        </svg>
      </div>
      <span className="text-xs text-gray-400">Integrations</span>
    </div>
  </aside>
); */