import React, { useEffect, useMemo, useState } from "react";
import {
    addBaseURL
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Layout from '../Pagelayout/Layout';
import SnackbarComp from "../Snackbar/Snackbar";
import Version from "./Version/Version";
import Table from "./Table/Table";

/*


IndividualTest component that renders the individual test case page.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the test cases when the component mounts.
It renders the Sidebar component.
The Sidebar component displays the list of assertions for the selected API.
This component renders on the /test/:id route.


*/


const BaseURL = () => {
    const [data, setData] = useState(null)
    const [selected, setSelected] = useState(0);

    const { loading, baseUrls } = useSelector((state) => state.apis);

    useEffect(() => {
        if (baseUrls && baseUrls.data) {
            setData([...baseUrls.data.urls, { source: '', baseUrl: '' }])
        }
    }, [baseUrls]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleUpdate = async () => {
        try {
            let formattedData = data.filter((item) => item.source !== '' && item.baseUrl !== '')
            await dispatch(addBaseURL(formattedData));
            setAlertSeverity("success");
            setAlertMessage("Base URLs updated successfully");
            setSnackbarOpen(true);
        } catch (error) {
            setAlertSeverity("error");
            setAlertMessage("Error updating base URLs");
            setSnackbarOpen(true);
        }
    }

    return (
        <Layout>
            <div className="text-white h-screen overflow-y-scroll no-scrollbar flex w-full gap-4 p-6 text-[#B0B0B0]">
                <MdOutlineArrowBackIos className="cursor-pointer my-2" onClick={() => navigate(-1)} />
                <div className="w-full flex flex-col gap-6">
                    <div className="text-xl text-[#D9D9D9]">Version</div>
                    <div className="w-1/2">
                        <Version />
                    </div>
                    <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0] col-span-2 w-full">
                        <div className="border-b border-[#2C2E33] ">
                            <div className={"px-6 py-2 cursor-pointer border-b-2 border-[#E27AAB] text-[#D9D9D9] w-max"}>Base URLs</div>
                        </div>
                        <div className="max-h-[25vh]">
                            <Table data={data} setData={setData} selected={selected} setSelected={setSelected} />
                        </div>
                    </div>
                    <div onClick={handleUpdate} className="px-2 py-1.5 rounded-md border border-[#E27AAB] bg-[#D85C93] flex items-center self-end cursor-pointer">Update</div>
                </div>
            </div>
            <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
        </Layout >
    );
};


export default BaseURL;