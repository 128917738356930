import React, { useEffect, useState, useRef } from "react";
import "./AddTC.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    addTestCase,
    getAllAPIs,
    testIndividualTest,
} from "../../Actions/tcActions";
import APISequence from "./APISequence/APISequence";
import { fetchTC } from "../../Actions/tcActions";
import { useAuth } from "../../AuthContext";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Layout from '../Pagelayout/Layout';
import { FaRegSave } from "react-icons/fa";
import * as TemplateUtils from '../../utils/TestScriptTemplate';
import { IoMdPlay } from "react-icons/io";
import MethodPath from "../Details/MethodPath.js/MethodPath";
import SnackbarComp from "../Snackbar/Snackbar";
import RequestResponse from "./RequestResponse/RequestResponse";
import Header from "./Header/Header";

/*


AddTC component that renders the Add Test Case page of the application.
It uses the useNavigate hook from react-router-dom to navigate to different routes.
It contains a form to add a new test case.
The form includes fields for Test Case Title, Priority, and API Sequence.
It also includes a button to add a new API sequence.


*/


const AddTC = () => {
    const [allApis, setAllApis] = useState([]);
    const [template, setTemplate] = useState(
        TemplateUtils.createNewTemplate()
    );
    const [currentApi, setCurrentApi] = useState(null);
    const [addedApis, setAddedApis] = useState([]);
    const [description, setDescription] = useState(null);
    const [reqBody, setReqBody] = useState(null);
    const [resContent, setResContent] = useState(null);
    const [headers, setHeaders] = useState({});
    const [flag, setFlag] = useState(false);


    const navigate = useNavigate();


    const {
        allApis: apis,
        loading,
        message,
        error,
    } = useSelector((state) => state.apis); // Get the list of apis from the state


    const { user, apikey } = useAuth();


    const dispatch = useDispatch();


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");


    useEffect(() => {
        // dispatch(fetchReqResByAPI(user?._id))
        dispatch(getAllAPIs());
    }, []);


    useEffect(() => {
        if (apis) {
            const tempApis = [];
            apis?.apiNames?.forEach((api) => {
                tempApis.push(api);
            });
            setAllApis(tempApis);
        }
    }, [apis]);


    const handleAddApi = (template, apis) => {
        let updatedTemplate = { ...template };
        apis.forEach((api) => {
            updatedTemplate = TemplateUtils.addApi(updatedTemplate, {
                ...api
            });
        })


        return updatedTemplate
    };


    const handleUpdateTemplate = (template, desc) => {
        const updatedTemplate = TemplateUtils.updateTemplate(template, {
            name: desc,
            description: desc
        });


        return updatedTemplate
    };


    const handleValidation = () => {
        const validationResult = TemplateUtils.validateTemplate(template);
        if (!validationResult.isValid) {
            console.error(validationResult.errors);
        }
    };


    const handleAddTC = async () => {
        try {
            if (flag) {
                dispatch(addTestCase({ template, apikey }));
            } else {
                setSnackbarOpen(true);
                setAlertSeverity("error");
                setAlertMessage("Please run the test case before adding it");
            }
        } catch (error) {
            // On error
            setAlertSeverity("error");
            setAlertMessage("Failed to Add Test Case");
            setSnackbarOpen(true);
        } finally {
            setAlertSeverity("success");
            setAlertMessage("Successfully Added Test Case");
            setSnackbarOpen(true);
        }
    };


    useEffect(() => {
        if (message) {
            dispatch(fetchTC());
            navigate("/test-suite");
        }
        if (error) {
            // console.log(error);
        }
    }, [message, error]);

    console.log(addedApis)

    const runTest = async () => {
        let temp = handleUpdateTemplate(template, description);
        let updatedTemplate = handleAddApi(temp, addedApis)
        setTemplate(updatedTemplate);
        // console.log('hello', updatedTemplate);
        try {
            dispatch(testIndividualTest(updatedTemplate, user?._id, apikey))
        } catch (error) {
            setAlertSeverity("error");
            setAlertMessage("Failed to Run Test Case");
            setSnackbarOpen(true);
            setFlag(false)
        } finally {
            setAlertSeverity("success");
            setAlertMessage("Successfully Ran Test Case");
            setSnackbarOpen(true);
            setFlag(true);
        }
    }


    return (
        <Layout loading={loading}>
            <div className="col-span-9 w-full flex h-full">
                <div className="w-[20%] px-2 py-4 border-r border-[#2C2E33] h-full">
                    <div className="flex gap-6 text items-center mb-12">
                        <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer" onClick={() => navigate("/test-suite")} />
                        <span className="text-[#A0A0A0]">Add New Test Case</span>
                    </div>
                    <div className="text-white">Add Test Steps</div>
                    <APISequence
                        setHeaders={setHeaders}
                        setResContent={setResContent}
                        setReqBody={setReqBody}
                        allApis={allApis}
                        setCurrentApi={setCurrentApi}
                        addedApis={addedApis}
                        currentApi={currentApi}
                        setAddedApis={setAddedApis}
                    />
                </div>
                <div className="w-[80%] px-3 py-4 overflow-y-scroll no-scrollbar h-full">
                    <div className="tc-title">
                        <div className="flex justify-between items-center">
                            <div className="text-[#B0B0B0] text-lg">
                                Test Case Description <span className="text-[#D9509B]">*</span>
                            </div>
                            <div className="flex gap-2">
                                <div className={`flex gap-2 items-center rounded px-2.5 py-1 border border-[#2C2E33] text-dashboard-text-gray mb-2 cursor-pointer ${!description || addedApis.length === 0 ? "" : "bg-[#1D1E20]"}`} onClick={(!description || addedApis.length === 0) && runTest}>
                                    <IoMdPlay /> Run Test Case
                                </div>
                                <div className={`flex gap-2 items-center rounded px-2.5 py-1 border border-[#2C2E33] text-dashboard-text-gray mb-2 cursor-pointer ${!description || addedApis.length === 0 || !flag ? "" : "bg-[#1D1E20]"}`} onClick={(!description || !addedApis[0]) ? () => {
                                    setSnackbarOpen(true);
                                    setAlertSeverity("error");
                                    setAlertMessage(!description ? "Please enter a description" : "Please add an API");
                                    setTimeout(() => {
                                        setSnackbarOpen(false);
                                    }, 1000);
                                } : handleAddTC}>
                                    <FaRegSave /> Save Test Case
                                </div>
                            </div>
                        </div>
                        <div className="rounded-md border border-[#2C2E33] bg-inherit w-full">
                            <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter Test Case Description"
                                className="w-full min-h-[20vh] bg-inherit p-2 focus:outline-none active:outline-none"
                            />
                        </div>
                    </div>
                    <MethodPath method={currentApi?.method} path={currentApi?.endpoint} extraClasses="my-4" />
                    <Header addedApis={addedApis} setAddedApis={setAddedApis} currentApi={currentApi} headers={headers} setHeaders={setHeaders} /> 
                    <RequestResponse addedApis={addedApis} reqBody={reqBody} resContent={resContent} currentApi={currentApi} 
                    setAddedApis={setAddedApis} setReqBody={setReqBody} setResContent={setResContent} />
                </div>
            </div>
            
            <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
        </Layout>
    );
};


export default AddTC;
