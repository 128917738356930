import Left from '../../icons/chevron-left.svg';
import Right from '../../icons/chevron-right.svg';

const Pagination = ({ length, handlePrevPage, handleNextPage, currentPage, setCurrentPage }) => {
    const itemsPerPage = 8;
    const startIdx = (currentPage - 1) * itemsPerPage;

    return <div className="flex items-center self-end mt-4">
        <div className="flex text-dashboard-text-graylight mr-2">
            <div
                className="rounded-md flex items-center border border-1 cursor-pointer border-dashboard-border-500 text-sm mr-3 px-2.5 py-1"
                onClick={handlePrevPage}
            >
                <img src={Left} className="mr-2" /> Prev
            </div>
            <div
                className={
                    "grid gap-2 mr-3 " +
                    (startIdx + itemsPerPage >= length
                        ? "grid-cols-2"
                        : "grid-cols-3")
                }
            >
                {startIdx + itemsPerPage >= length ? (
                    <>
                        <div onClick={() => setCurrentPage(currentPage - 1)} className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0] cursor-pointer">
                            {currentPage - 1}
                        </div>
                        <div className="text-white border border-gradient-primary-to p-1 w-[34px] bg-gradient-primary-to text-center rounded-md cursor-pointer">
                            {currentPage}
                        </div>
                    </>
                ) : currentPage > 1 ? (
                    <>
                        <div onClick={() => setCurrentPage(currentPage - 1)} className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0] cursor-pointer">
                            {currentPage - 1}
                        </div>
                        <div className="text-white border border-gradient-primary-to p-1 w-[34px] bg-gradient-primary-to text-center rounded-md cursor-pointer">
                            {currentPage}
                        </div>
                        <div onClick={() => setCurrentPage(currentPage + 1)} className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0] cursor-pointer">
                            {currentPage + 1}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-white border border-gradient-primary-to bg-gradient-primary-to p-1 w-[34px] text-center rounded-md cursor-pointer">
                            {currentPage}
                        </div>
                        {startIdx + itemsPerPage <= length ? <div onClick={() => setCurrentPage(currentPage + 1)} className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0] cursor-pointer">
                            {currentPage + 1}
                        </div> : null}
                        {startIdx + itemsPerPage <= length ? <div onClick={() => setCurrentPage(currentPage + 2)} className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0] cursor-pointer">
                            {currentPage + 2}
                        </div> : null}
                    </>
                )}
            </div>
            <div
                className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-sm px-3 py-1 mr-4"
                onClick={handleNextPage}
            >
                Next <img className="ml-2" src={Right} />
            </div>
        </div>
        <div className="text-[#EFEFEF] text-sm font-light">
            {" "}
            {startIdx + 1 === length
                ? startIdx + 1
                : startIdx + itemsPerPage > length
                    ? length
                    : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {length} Results
        </div>
    </div>
}

export default Pagination;