import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addBaseURL, downloadSwaggerDoc, getAllAPIs, triggerE2E, getSources } from "../../../Actions/tcActions";
import { Button } from "@mui/material";
import { PiPlus } from "react-icons/pi";
import CopyIcon from "../../../icons/copy-icon.svg";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "../../../utils/common";
import SnackbarComp from "../../Snackbar/Snackbar";
import Pagination from "../../Pagination/Pagination";
import Buttons from "../Buttons/Buttons";
import OnboardingDialog from "../../Dialog/Onboarding/OnboardingDialog";
import JSONPrettyComp from "../../Details/JSONPretty/JSONPretty";
import Table from "../../BaseURL/Table/Table";
import Version from "../../BaseURL/Version/Version";


const SwaggerDoc = () => {


  const { t } = useTranslation();


  const dispatch = useDispatch();
  const apis = useSelector((state) => state.apis);
  const [allApis, setAllApis] = useState(null);
  const [runModalOpen, setRunModalOpen] = useState(false);
  const [apiModalOpen, setApiModalOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const sources = useSelector((state) => state.apis?.sources?.data);
  const [baseUrls, setBaseUrls] = useState([])
  const [selected, setSelected] = useState(0);


  const {
    loading: apiLoading,
    swaggerDoc
  } = useSelector((state) => state.apis);


  const { user, apikey, logout } = useAuth();


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const data = [];
  const getMethodClasses = (method) => {
    switch (method?.toLowerCase()) {
      case "post":
        return "bg-[#816F33]/40 text-[#FCD34D]";
      case "get":
        return "bg-[#31744A]/40 text-[#4ADE80]";
      case "put":
        return "bg-[#2F5A83]/40 text-[#44A5FF]";
      case "delete":
        return "bg-[#804343]/40 text-[#F87171]";
      case "patch":
        return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
  };


  const navigate = useNavigate();


  const key = apikey;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 14;
  const totalPages = Math.ceil(apis?.allApis?.apiNames.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = apis?.allApis?.apiNames?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  useEffect(() => {
    const fetchSources = async () => {
      await dispatch(getSources());
    };
    fetchSources();
  }, []);

  useEffect(() => {
    if (sources) {
      console.log("sources", sources);
      const urls = []
      for (let i = 0; i < sources.length; i++) {
        urls.push({ source: sources[i], baseUrl: "" });
      }
      urls.push({ source: "", baseUrl: "" }); 
      setBaseUrls(urls)
    }
  }, [sources])

  // useEffect(() => {
  //   const sourcesData = sources?.sources?.data || [];
  //   console.log(sourcesData);
  //   const updatedBaseUrls = baseUrls.map((url, index) => {
  //     if (index < sourcesData.length) {
  //       return { ...url, source: sourcesData[index] };
  //     }
  //     return url;
  //   });
  //   console.log(updatedBaseUrls);
  //   setBaseUrls(updatedBaseUrls);
  // }, [sources]);


  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };


  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const handleRunModalOpen = () => {
    setRunModalOpen(true);
  };

  const handleRunModalClose = () => {
    setRunModalOpen(false);
  };


  const handleLogout = async () => {
    await logout();
  }

  const runAllTestsHandler = async (e) => {
    try {
      setLoading(true);
      let formattedBaseUrls = []
      for (let i = 0; i < baseUrls.length; i++) {
        if (baseUrls[i].source !== '' && baseUrls[i].baseUrl !== '') {
          formattedBaseUrls.push(baseUrls[i])
        }
      }
      await dispatch({ type: "onboardingComplete" });
      await dispatch(triggerE2E(baseUrls[selected].baseUrl, key));
      await dispatch(addBaseURL(formattedBaseUrls));
      localStorage.setItem('flag', true);
      setLoading(false);
      navigate("/test-suite");
    } catch (error) {
      // console.log(error);
    }
  };

  const topButtonsConfig = [
    { title: 'Downdload', action: () => dispatch(downloadSwaggerDoc()), type: 'primary'},
    { title: 'Logout', action: handleLogout },
]

  return (<div className="flex flex-col overflow-x-hidden no-scrollbar">
    <div className="px-24 pt-10 flex flex-col">
      <div className="flex justify-between">
        <div className="text-[#F37BB3] text-xl font-semibold">
          Swagger Document
        </div>
        <Buttons extraClasses="relative" config={topButtonsConfig} />
      </div>
      <div className="grid grid-rows-7 grid-cols-2 gap-x-4 gap-y-3 mt-8">
        {currentApis?.map((api) => (
          <div className="bg-[#0F1011] border border-1 border-[#2E3138] rounded-md flex px-4 py-2.5 items-center">
            <div
              className={
                "mr-4 rounded-md min-w-[68px] text-center py-1.5 px-2 text-caption font-semibold uppercase " +
                getMethodClasses(api.source.request.method)
              }
            >
              {api.source.request.method}
            </div>
            <div className="flex justify-between gap-2 w-[85%] max-w-[85%] relative items-center">
              <div className="relative w-[75%] max-h-[40px] max-w-[75%] overflow-y-scroll no-scrollbar font-semibold">
                <div>{api.name}</div>
                <div className="text-[10px] break-words text-wrap mt-1 text-[#B0B0B0]">
                  {String(api.source.request.path)}
                </div>
              </div>
              <div
                onClick={() => {
                  setApiModalOpen(true);
                  setSelectedApi(api);
                }}
                className="bg-[#16171A] hover:bg-[#1E1F23] min-[100px] text-center border border-1 border-[#43454A] px-3 py-1 rounded text-sm cursor-pointer"
              >
                view more
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center w-full mt-4">
        <Pagination length={apis?.allApis?.apiNames?.length} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        <div>
          Number of APIs captured{" "}
          <span className="ml-3 px-3 py-[3px] bg-dashboard-dark-100 border border-dashboard-border-500 rounded text-paragraph">
            {apis?.allApis?.apiNames.length}
          </span>
        </div>
      </div>
      <div
        onClick={handleRunModalOpen}
        className="cursor-pointer px-4 py-1.5 mt-8 rounded-md bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to hover:from-gradient-primary-active-from hover:to-gradient-primary-active-to transition text-center self-center"
      >
        {t("actions.proceed_to_analysis")}
      </div>
      <OnboardingDialog width="60vw" open={runModalOpen} onClose={handleRunModalClose}
      body={<div className="flex flex-col">
        <div>
          <div
            onClick={handleRunModalClose}
            className="text-white cursor-pointer mt-8 mr-8 justify-self-end"
          >
            <PiPlus className="rotate-45" size={18} />
          </div>
          <div className="p-10 pt-4">
            <div className="text-xl text-[#D9D9D9]">Version</div>
            <div className="w-1/2 mt-4 mb-8">
              <Version />
            </div>
            <div className="text-xl text-[#D9D9D9] mb-4">Add Base URL</div>
            <div className="rounded-md text-dashboard-text-gray border border-dashboard-border-500">
              <div className="bg-dashboard-dark-600 border-b border-dashboard-border-500 grid grid-cols-12">
                <div className="col-span-1" />
                <div className="col-span-4 p-2.5 border-x border-dashboard-border-500" >Microservice Name</div>
                <div className="col-span-6 p-2.5 border-r border-dashboard-border-500" >URL</div>
                <div className="col-span-1" />
              </div>
              <div className="max-h-[25vh]">
                <Table data={baseUrls} setData={setBaseUrls} selected={selected} setSelected={setSelected} />
              </div> 
            </div>
          </div>
          <div className="flex justify-end gap-3 px-12 py-4 border-t border-dashboard-border-500 items-center">
            <div onClick={handleRunModalClose} className="cursor-pointer rounded bg-dashboard-dark-100 hover:[#CC3F8D] border border-[#35383E] px-5 py-2"
            >
              Cancel
            </div>
            <Button
              variant="contained"
              className="loginButton"
              sx={{
                padding: "8px 20px",
                fontSize: "12px",
                fontFamily: "Roboto",
                borderRadius: "4px",
                border: "1px solid #E27AAB",
                backgroundImage: 'linear-gradient(to bottom, #E27AAB, #D85C93)',
                ":hover": {
                  backgroundImage: 'linear-gradient(to bottom, #DD6B9F, #E27AAB)',
                },
                textTransform: "unset",
              }}
              onClick={runAllTestsHandler}
            >
              Confirm & Run Test Cases
            </Button>
          </div>
        </div>
      </div>} />
      <OnboardingDialog width="80vw" open={apiModalOpen}  onClose={() => setApiModalOpen(false)}
      body={<div className="p-12 pb-8 w-full flex flex-col gap-2">
        <div
          onClick={() => setApiModalOpen(false)}
          className="rotate-45 cursor-pointer text-[#B0B0B0] absolute top-4 right-4"
        >
          <PiPlus size={20} />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div
              className={
                "mr-4 rounded-md min-w-[80px] text-center py-1.5 text-sm font-semibold " +
                getMethodClasses(selectedApi?.source?.request?.method)
              }
            >
              {selectedApi?.source.request.method}
            </div>
            <div className="font-semibold">{selectedApi?.name}</div>
          </div>
          <div className="rounded-md border border-1 bg-[#0F1011] text-nowrap border-[#2C2E33] px-3 py-1.5 min-w-[10vw] max-w-[30vw] overflow-x-scroll no-scrollbar text-sm">
            {selectedApi?.source.request.path}
          </div>
        </div>
        {selectedApi?.source?.request?.headers ? (
          <div className="mt-4">
            <div className="font-semibold">Headers</div>
            <div className="text-sm font-light border border-1 border-[#2C2E33] rounded-md max-h-[40vh] mt-2 overflow-y-scroll no-scrollbar">
              {Object.entries(selectedApi?.source?.request?.headers).map(
                ([key, value]) => (
                  <div className="grid grid-cols-6">
                    <div className="col-span-2 px-4 py-2 bg-[#0F1011] border-b border-r border-[#2C2E33]">
                      {key}
                    </div>
                    <div className="col-span-4 overflow-x-scroll no-scrollbar whitespace-normal px-4 py-2 bg-[#0F1011] border-b border-[#2C2E33]">
                      {String(value)}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}
        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
            <div className="bg-[#17181A] border-b border-[#2C2E33] p-3 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <div className="border border-[#533543] rounded-md px-3 py-0.5">1</div>
                <select name="body1" id="body1" className="border border-[#533543] bg-inherit rounded-md min-w-[200px] px-3 py-1">
                  <option value="body1">Request Body 1</option>
                </select>
              </div>
              <img
                onClick={() =>
                  copyToClipboard(
                    JSON.stringify(selectedApi?.source?.request?.body ?? {}), setSnackbarOpen, setAlertSeverity, setAlertMessage
                  )
                }
                className="w-[16px] h-[16px] cursor-pointer"
                src={CopyIcon}
                alt="copy icon"
              />
            </div>
            <div className="h-[30vh] max-h-[30vh] px-3 py-3 bg-[#0F1011] text-[#B0B0B0]">
              <JSONPrettyComp data={JSON.stringify(selectedApi?.source?.request?.body ?? {})} />
            </div>
          </div>
          <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
            <div className="bg-[#17181A] border-b border-[#2C2E33] px-3 py-4 flex justify-between items-center">
              Response{" "}
              <img
                onClick={() =>
                  copyToClipboard(
                    JSON.stringify(
                      selectedApi?.source?.response?.content ?? {}
                    ), setSnackbarOpen, setAlertSeverity, setAlertMessage
                  )
                }
                className="w-[16px] h-[16px] cursor-pointer"
                src={CopyIcon}
                alt="copy icon"
              />
            </div>
            <div className="h-[30vh] max-h-[30vh] p-3 bg-[#0F1011] text-[#B0B0B0] relative">
              <JSONPrettyComp data={JSON.stringify(selectedApi?.source?.response?.content ?? {})} />
              <div className={"absolute top-3 right-3 rounded-md max-h-fit py-0.5 px-3 text-white " + (selectedApi?.source?.response?.status_code < (300) ? "bg-[#31744A]" : "bg-[#633238]")}>{selectedApi?.source?.response?.status_code}</div>
            </div>
          </div>
        </div>
      </div>} />
      <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
    </div>
  </div>
  );
};


export default SwaggerDoc;