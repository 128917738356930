import { useState } from 'react';
import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import { ContentCopyOutlined } from '@mui/icons-material';
import ProfileInput from '../Components/ProfileInputs';
import { useAuth } from '../../../AuthContext';
import { LuRefreshCcw } from "react-icons/lu";
import { Dialog} from "@mui/material";
import { PiPlus } from "react-icons/pi";
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarComp from '../../../components/Snackbar/Snackbar';
import SuiteDialog from '../../../components/Dialog/Suite/SuiteDialog';


const ProfileSettings = () => {
    const { user, updateUser, apikey, getnewapikey } = useAuth();

    const [data, setData] = useState({
        email: user?.email,
        username: user?.name,
        designation: user?.orgDetails?.designation,
        organisation: user?.orgDetails?.organisation
    })
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const fields = [
        { label: 'Email', placeholder: 'example@gmail.com' },
        { label: 'Username', placeholder: 'Username123' },
        { label: 'Designation', placeholder: 'Your Designation' },
        { label: 'Organisation', placeholder: 'Name of your Organisation' }
    ];

    const handleProfileUpdate = async () => {
        try {
            setLoading(true);
            await updateUser({
                email: data.email,
                name: data.username,
                orgDetails: {
                    designation: data?.designation || '',
                    organisation: data?.organisation || ''
                }
            });
            setAlertSeverity("success");
            setAlertMessage("Successfully updated user details");
            setSnackbarOpen(true);
            setLoading(false);
        } catch (error) {
            setAlertSeverity("error");
            setAlertMessage("Failed to update user details");
            setSnackbarOpen(true);
            setLoading(false);
        }
    }

    const handleAPIKeyRegenerate = () => {
        getnewapikey();
        setModalOpen(!modalOpen);
    }

    return (
        <div className='overflow-y-auto h-full no-scrollbar'>
            <Panel>
                <SettingsHeading variant='profile' />
            </Panel>
            <Panel>
                <div className="flex flex-col gap-[12px] pb-16 mb-16 border-b border-[#2C2E33]">
                    <div className="text-xl text-[#D9D9D9]">
                        API Key
                    </div>
                    <p className="text-[#B0B0B0]">
                        Easily refresh your API key to generate a new one, automatically replacing and deleting the old key for secure access
                    </p>
                    <div className="w-full flex gap-[50px]">
                        <div className="relative w-[548px]">
                            <input
                                type="text"
                                placeholder="API Key"
                                className="w-full h-[41px] px-[14px] pr-[40px] py-[10px] text-text-primary rounded-[6px] bg-[#0F1011] border border-[#2C2E33]"
                                disabled={true}
                                value={apikey}
                            />
                            <button
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                                onClick={() => navigator.clipboard.writeText(apikey)}
                            >
                                <ContentCopyOutlined className="w-5 h-5" />
                            </button>
                        </div>
                        <button
                            onClick={() => setModalOpen(!modalOpen)}
                            className="p-2 px-3 rounded-md bg-[#1D1E20] flex items-center gap-2 text-white"
                        >
                            <LuRefreshCcw className="h-4 w-4" />
                            <span>Generate New Key</span>
                        </button>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="flex flex-col gap-[12px] mb-8">
                    <div className="text-xl text-[#D9D9D9]">
                        User details
                    </div>
                    <p className="text-[#B0B0B0]">
                        Manage and update your personal information to keep your profile accurate and up-to-date
                    </p>
                </div>
            </Panel>
            <Panel>
                <div className="grid grid-cols-2 gap-16">
                    {fields.map((field, index) => (
                        <ProfileInput
                            data={data}
                            handleProfileChange={(e) => setData({ ...data, [field.label.toLowerCase()]: e.target.value })}
                            key={index}
                            label={field.label}
                            placeholder={field.placeholder}
                        />
                    ))}
                </div>
                <div className='w-full flex justify-end my-6'>
                    <button
                        onClick={handleProfileUpdate}
                        className="px-2 py-1.5 rounded-md border border-[#E27AAB] bg-[#D85C93] flex items-center space-x-2 text-white w-[100px] h-[34px] justify-center"
                    >
                        {loading ? <CircularProgress size={14} color='inherit' /> : <span>Update</span>}
                    </button>
                </div>
            </Panel>
            <SuiteDialog width="45vw" open={modalOpen}
            body={<div className="flex flex-col">
                <div
                    onClick={() => setModalOpen(false)}
                    className="text-dashboard-text-graylight cursor-pointer self-end mt-6 mr-6"
                >
                    <PiPlus className="rotate-45" size={20} />
                </div>
                <div className="w-full py-10 px-16">
                    <div className='text-xl'>Are you sure you want to regenerate the API key</div>
                    <div className='bg-dashboard-dark-700 rounded-md border border-dashboard-dark-400 p-2 my-6 text-dashboard-text-graylight'>{apikey}</div>
                    <div>This action cannot be undone and the current API key will be replaced with a new key</div>
                </div>
                <div className='p-3 flex justify-end w-full  border-t border-dashboard-dark-400 cursor-pointer'>
                    <div className="bg-dashboard-dark-600 rounded-md px-3 py-2 border border-dashboard-dark-400" onClick={handleAPIKeyRegenerate}>Regenerate</div>
                </div>
            </div>} />
            <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
        </div>
    );
};

export default ProfileSettings;