import { useState, useMemo } from 'react';
import { FilterIcon, AddIcon, SortIcon, RunIcon, SearchIcon, ShareIcon } from "../../icons";
import TestRunSelector from "../Controls/TestRunSelector";



//SearchBar Component
const SearchBar = ({ search, handleSearch, type }) => {
    console.log(type);
    return (
        <div className="relative border-l border-dashboard-dark-400 w-[35vw] text-secondary">
            <input
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                type="text"
                placeholder={`Search ${type === 'bugs' ? 'For Bugs...' : 'Test Cases'}`}
                className="bg-dashboard-dark-800 px-2 py-1.5 w-96 pl-10 w-full text-dashboard-text-gray focus:outline-none active:outline-none"
            />
            <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-dashboard-text-gray w-4 h-4" />
        </div>
    );
};




//FilterButton Component
const FilterButton = ({ selectedOptions, setSelectedOptions }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    const handleReset = () => {
        setSelectedOptions({ priority: [], status: [] })
        setOpen(false)
    }

    const arr = [
        { first: 'Priority', second: ['Low', 'Medium', 'High'] },
        { first: 'Status', second: ['Passed', 'Failed'] },
        { first: 'Reset', action: handleReset }
    ]

    const handleUnselect = (item, type) => {
        type = type.toLowerCase()
        let temp = [...selectedOptions[type]]
        if (!selectedOptions[type]?.includes(item)) temp.push(item)
        else if (selectedOptions[type]?.includes(item)) temp = temp.filter((i) => i !== item)
        setSelectedOptions({ ...selectedOptions, [type]: temp })
    }

    return <div>
        <div className={`hover:bg-dashboard-dark-600 p-1 rounded cursor-pointer relative ${open && "bg-dashboard-dark-600"}`}>
            <FilterIcon className="text-dashboard-text-gray hover:text-white h-5 w-5" onClick={() => setOpen(!open)} />
            <div className='absolute top-8 -left-16'>
                {open && <div className="flex gap-1">
                    <div className='bg-dashboard-dark-100 rounded-md p-1.5 border border-dashboard-border-500 w-[120px]'>
                        {arr.map((item, index) => <div onClick={Object.hasOwn(item, 'action') ? () => { item.action(); setSelected(index); } : () => setSelected(index)} className={`hover:bg-dashboard-dark-600 rounded text-white py-1 px-2.5 cursor-pointer ${selected === index && "bg-dashboard-dark-600"}`}>{item.first}</div>)}
                    </div>
                    {(arr[selected]?.second) && <div className='bg-dashboard-dark-100 rounded-md p-1.5 border border-dashboard-border-500 w-[120px]'>
                        {arr[selected]?.second.map(item => <div onClick={() => handleUnselect(item, arr[selected]?.first)} className='hover:bg-dashboard-dark-600 rounded text-white py-1 px-2.5 cursor-pointer flex gap-2 items-center'>
                            <input
                                // style={{ marginRight: "5px" }}
                                type="checkbox"
                                id="apiSelected"
                                name="apiSelected"
                                on
                                checked={selectedOptions[(arr[selected]?.first).toLowerCase()]?.includes(item)}
                            />
                            {item}
                        </div>)}
                    </div>}
                </div>}
            </div>
            {(selectedOptions.priority.length > 0 || selectedOptions.status.length > 0) && <div className='w-[7px] h-[7px] bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to absolute -top-1 -right-1 rounded-full' />}
        </div>
    </div>
        ;
};




//SortButton Component
const SortButton = () => {
    return (
        <SortIcon className="text-dashboard-text-gray w-5 h-5 cursor-pointer" />
    );
};




//AddTestCaseButton Component
const AddTestCaseButton = ({ onAddTestCase }) => {
    return (
        <button
            onClick={onAddTestCase}
            className="px-3 py-1 text-dashboard-text-graylight rounded border border-dashboard-dark-400 flex items-center gap-2"
        >
            <AddIcon className="h-4 w-4" />
            <span>Add TestCase</span>
        </button>
    );
};




//ShareButton Component
const ShareButton = () => {
    return (
        <button className="px-3 py-1 text-dashboard-text-graylight rounded border border-dashboard-dark-400 flex items-center gap-2">
            <ShareIcon className="h-4 w-4" />
            <span>Share</span>
        </button>
    );
};




//RunTestSuiteButton Component
const RunTestSuiteButton = ({ onRunTestSuite }) => {
    return (
        <button
            onClick={onRunTestSuite}
            className="px-3 py-1 text-dashboard-text-graylight rounded border border-[#E27AAB] bg-[#D85C93] flex items-center gap-2"
        >
            <RunIcon className="w-4 h-4 text-white fill-current" />
            <span className="text-white">Run Test Suite</span>
        </button>
    );
};




const SelectAllButton = ({ selectAll, setSelectAll }) => {
    return (
        <div className="flex items-center gap-3">
            <input
                // style={{ marginRight: "5px" }}
                type="checkbox"
                id="apiSelected"
                name="apiSelected"
                checked={selectAll}
                onClick={() => setSelectAll(!selectAll)}
            />
            <div>Select All</div>
        </div>
    )
}




//Control Components Mapping
const controlComponentMap = {
    SelectAllButton: () => <SelectAllButton />,
    SearchBar: (props) => (
        <SearchBar
            search={props.search}
            handleSearch={props.handleSearch}
            type={props.type}
        />
    ),
    FilterButton: (props) => <FilterButton selectedOptions={props.selectedOptions} setSelectedOptions={props.setSelectedOptions} />,
    SortButton: () => <SortButton />,
    TestRunSelector: (props) => (
        <TestRunSelector
            testRunId={props.testRunId}
        />
    ),
    AddTestCaseButton: (props) => (
        <AddTestCaseButton
            onAddTestCase={props.onAddTestCase}
        />
    ),
    ShareButton: () => <ShareButton />,
    RunTestSuiteButton: (props) => (
        <RunTestSuiteButton
            onRunTestSuite={props.onRunTestSuite}
        />
    ),
};




// Render multiple components based on the array of component types
const renderComponent = (filteredComponents) => {
    return filteredComponents.map((config, index) => {
        const Component = controlComponentMap[config.type];




        // If no matching component is found, return null
        if (!Component) {
            console.warn(`No component found for type: ${config.type}`);
            return null;
        }




        return <Component key={index} {...config.props} />
    }).filter(Boolean); // Remove any null components
};




//Control Component
export const Controls = ({ components }) => {
    const firstGroupComponents = useMemo(() =>
        components.filter((config) => config.group === "first"),
        [components]
    );




    const secondGroupComponents = useMemo(() =>
        components.filter((config) => config.group === "second"),
        [components]
    );




    return (
        <div className="sticky top-0 z-20"> {/* Make the header sticky */}
            <div className="px-4 py-2 border-b border-dashboard-dark-400 w-full">
                <div className="flex items-center justify-between">
                    {/* First Group of Components */}
                    <div className="flex items-center gap-4">
                        {renderComponent(firstGroupComponents)}
                    </div>
                    {/* Second Group of Components */}
                    <div className="flex items-center gap-4">
                        {renderComponent(secondGroupComponents)}
                    </div>
                </div>
            </div>
        </div>
    );
};
