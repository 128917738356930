import { CalendarIcon, ClockIcon, DurationIcon, PriorityIcon } from "../../../icons";
import InfoBadge from "./InfoBadge";

const DateTime = ({date, time, duration, priority}) => {
    return <div className="flex items-center space-x-2 text-sm text-dashboard-text-gray">
        {date && <InfoBadge icon={CalendarIcon} value={date} />}
        {time && <InfoBadge icon={ClockIcon} value={time} />}
        {duration && <InfoBadge icon={DurationIcon} value={duration / 1000} />}
        {priority && <InfoBadge
            icon={() => <PriorityIcon priority={priority} />}
            value={priority}
        />}
    </div>
}

export default DateTime;