import React, { useEffect, useState } from 'react'
import './IndividualBug.css'
import Layout from '../Pagelayout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchIndividualAPI, fetchRelatedTC } from '../../Actions/tcActions';
import { stringify as uuidStringify } from 'uuid';
import { getStatusStyles } from '../../utils/ui';
import { useNavigate } from 'react-router-dom';
import { FiArrowUpRight } from "react-icons/fi";
import { MdOutlineArrowBackIos } from "react-icons/md";
import MethodPath from '../Details/MethodPath.js/MethodPath';
import DateTime from '../Details/DateTime.js/DateTime';
import ReqRes from '../Details/ReqRes/ReqRes';

/*


IndividualBug component that renders the individual bug page.
The IndividualBug component displays the details of the individual bug.
The component uses the ReqResBody component to display the request, response, and actual response.
The component uses the APISidebar component to display the list of APIs.


*/
const IndividualBug = () => {
    const [toggleState, setToggleState] = useState(1);
    const [expand, setExpand] = useState({ type: '', val: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(false);


    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        loading: testsCasesLoading,
        relatedTC,
        bugs
    } = useSelector((state) => state.testCases);


    const { individualAPI, loading } = useSelector(
        (state) => state.apis
    ); // Load individual API from the state


    const bug = bugs?.find(bug => bug._id === id);


    useEffect(() => {
        dispatch(fetchIndividualAPI(uuidStringify(bug.api.data)));
        dispatch(fetchRelatedTC(bug.title));
    }, [])

    
    const styles = getStatusStyles(bug?.status, bug?.ignore)

    const reqConfig = [
        {name: "Request", data: individualAPI?.data?.assertions?.body}
    ]

    const resConfig = [
        {name: "Expected Response", data: individualAPI?.data?.assertions?.response?.expected},
        {name: "Actual Response", data: individualAPI?.data?.assertions?.response?.actual},
    ]

    if (!individualAPI?.data?.pass && individualAPI?.data?.assertions?.err?.message){
        resConfig.push({name: "Error Details", data: individualAPI?.data?.assertions?.err.message})

    }

    return (
        <Layout>
            <div className="px-4 py-6">
                <div className="text-[#A0A0A0] mb-2">{bug?.title}</div>
                <DateTime date={bug?.date} time={bug?.time} duration={bug?.duration} priority={bug?.priority} />
                <div className="grid grid-cols-9 gap-4 my-4">
                    <MethodPath method={individualAPI?.data?.method} path={individualAPI?.data?.path} extraClasses="col-span-8" />
                    {toggleState === 1 ? <div className={`px-2.5 py-1 rounded flex gap-2 items-center justify-center col-span-1 ${styles.container}`}>
                        <div className={`w-[10px] h-[10px] rounded-full ${styles.dot}`} />
                        <span>{bug?.status}</span>
                    </div> : <div onClick={() => setIsOpen(!isOpen)} className='relative p-2.5 bg-dashboard-dark-100 border border-dashboard-dark-600 rounded-md flex justify-between items-center col-span-1'>
                        <div className='flex items-center gap-2'>
                            <div className={`w-[10px] h-[10px] rounded-full ${!status ? 'bg-gradient-primary-active-to' : 'bg-[#44A5FF]'}`} /> {status ? 'Closed' : 'Open'}
                        </div>
                        <MdOutlineArrowBackIos className={`cursor-pointer text-dashboard-text-graylight text-h6 ${isOpen ? "rotate-90" : "-rotate-90"}`} />
                        {isOpen && <div className='absolute top-[59px] left-0 p-2.5 bg-dashboard-dark-100 border border-dashboard-dark-600 rounded-md w-full h-[55px] flex items-center cursor-pointer' onClick={() => setStatus(!status)}>
                            <div className='flex items-center gap-2'>
                                <div className={`w-[10px] h-[10px] rounded-full ${!status ? 'bg-[#44A5FF]' : 'bg-gradient-primary-active-to'}`} /> {!status ? 'Closed' : 'Open'}
                            </div>
                        </div>}
                    </div>
                    }
                </div>
                <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0] mb-3">
                    <div className="flex justify-start border-b border-[#2C2E33] bg-[#0F1011]">
                        <div className={"px-6 py-2 cursor-pointer" + (toggleState === 1 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(1)}>API Description</div>
                        <div className={"px-6 py-2 cursor-pointer" + (toggleState === 2 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(2)}>Related Test Cases</div>
                    </div>
                    {toggleState === 1 ? <div className='p-3'>API Description</div> : <div className='max-h-[35vh] overflow-y-auto no-scrollbar'>
                        <div className="border-b border-dashboard-border-500 grid grid-cols-11">
                            <span className="col-span-4 p-2.5" >Test Case ID</span>
                            <span className="col-span-7 p-2.5 border-l border-dashboard-border-500" >Description</span>
                        </div>
                        {relatedTC?.map(tc => tc?.status === status && <div className="border-b border-dashboard-border-500 grid grid-cols-11">
                            <span className="col-span-4 p-2.5" >TC{(tc?.tcNumber).toString().padStart(3, '0')}</span>
                            {/* <div className="col-span-6 p-2.5 border-x border-dashboard-border-500" ><span className={`px-2 py-1.5 rounded-md text-sm ${tc?.status ? "bg-dashboard-dark-201 text-dashboard-text-green" : "bg-dashboard-dark-200 text-dashboard-text-red"}`}>{tc?.status ? "Passed" : "Failed"}</span></div> */}
                            <span className="col-span-6 p-2.5 border-x border-dashboard-border-500">{tc?.title}</span>
                            <div className="col-span-1 text-[#494949] flex items-center justify-center" >
                                <span onClick={() => navigate(`/test/${tc?.parentSuiteId}`)} className="cursor-pointer rounded-md p-1.5 hover:text-white hover:bg-[#1D1E20]">
                                    <FiArrowUpRight />
                                </span>
                            </div>
                        </div>)}
                    </div>}
                </div>
                <div className='grid grid-cols-2 gap-3'>
                    {expand.type !== 'res' && <div className={`${expand.type === 'req' && expand.val ? 'col-span-2' : ''}`}>
                        <ReqRes type="req" config={reqConfig} expand={expand} setExpand={setExpand} />
                    </div>}
                    {expand.type !== 'req' && <div className={`${expand.type === 'res' && expand.val ? 'col-span-2' : ''}`}>
                        <ReqRes type="res" config={resConfig} expand={expand} setExpand={setExpand} duration={individualAPI?.data?.duration} status={individualAPI?.data?.assertions?.status?.actual} />
                    </div>}
                </div>
            </div>
        </Layout>
    )
}


export default IndividualBug
